.club-link {
  text-decoration: none;
  color: white;
}

.club-name {
  opacity: 0.7;
  transition: all 0.3s ease;
  display: inline-block;
  transform: translateY(0);
}

.club-name:hover {
  opacity: 1;
  color: #fff;
  transform: translateY(-3px);
}

.nav-btn {
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 6px 16px;
  border-radius: 20px;
  opacity: 0.85;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.nav-btn:hover {
  opacity: 1;
  color: white;
  text-shadow: 0 0 2px #fff;
  transform: translateY(-2px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
}
