.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-wrapper img {
  flex-shrink: 0;
  width: 100%;
  height: auto;
}