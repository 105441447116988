.login-form-container {
  background: rgba(255, 255, 255, 0.3); /* Slightly transparent background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(
    10px
  ); /* For better compatibility with WebKit browsers */
  border-radius: 10px; /* Optional: Rounds the corners */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Optional: Adds a shadow */
  padding: 20px; /* Optional: Adjust padding for content */
}
